import React, { Fragment } from "react";
import {
  SimpleForm,
  required,
  Create,
  SelectInput,
  useNotify,
  useRedirect,
  useRefresh,
  NumberInput,
  Confirm,
  SaveButton,
  Toolbar,
  useCreate,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  TextField as MuiTextField,
} from "@mui/material";
import mbaas from "../../../provider/Mbaas";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const CreateCurrencySaveButton = (props: any) => {
  const { openDialog } = props;

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  return <SaveButton type="button" onClick={openDialog} />;
};

const CreateCurrencyToolbar = (props: any) => {
  const { openDialog } = props;

  return (
    <Toolbar>
      <CreateCurrencySaveButton openDialog={openDialog} />
    </Toolbar>
  );
};

interface ICreatedData {
  origin: string;
  destination: string;
  rate: number;
}

export const CreatePage = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [createCurrencyRate, { data, isLoading, error, isSuccess }] =
    useCreate();
  const [otpCode, setOtpCode] = React.useState<string>("");
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [createdData, setCreatedData] = React.useState<ICreatedData>({
    origin: "",
    destination: "",
    rate: 0,
  });

  const handleConfirmDialogOpen = (e: any) => {
    e.preventDefault();
    setOpenConfirmDialog(true);
  };
  const handleConfirmDialogClose = () => setOpenConfirmDialog(false);

  // -- If success creating data
  if (isSuccess) {
    notify("ra.notification.created");
    redirect("/services/currencyRates");
    refresh();
  }
  // -- If error when creating data
  if (error) {
    notify("Create Data Failed", { type: "warning" });
    redirect("/services/currencyRates");
    refresh();
  }

  const handleValidateOtp = async () => {
    const { data: resultData, error } = await mbaas.client.invokeApi(
      "console-validate-totp",
      {
        token: otpCode,
      }
    );
    // --
    if (error) {
      Swal.fire({
        icon: "error",
        title: `Request failed: ${error.error}`,
        target: "#muiDrawer",
      });
    }
    // --
    if (resultData) {
      handleConfirmDialogClose();
      createCurrencyRate("currencyRates", {
        data: {
          createdData,
        },
      });
    }
  };

  return (
    <Fragment>
      <Create
        resource="currencyRates"
        title=" &rsaquo; Create"
        {...props}
        redirect="list"
      >
        <Box
          pt={5}
          width={{ xs: "100vW", sm: 600 }}
          mt={{ xs: 2, sm: 1 }}
          height="100vh"
        >
          <SimpleForm
            toolbar={
              <CreateCurrencyToolbar openDialog={handleConfirmDialogOpen} />
            }
          >
            <Typography variant="h6" style={{ marginBottom: "17px" }}>
              Create New Conversion Rate
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <SelectInput
                  source="origin"
                  data-test-id="origin"
                  validate={[required()]}
                  fullWidth
                  choices={[
                    { id: "HKD", name: "HKD" },
                    { id: "IDR", name: "IDR" },
                    { id: "TWD", name: "TWD" },
                    { id: "MYR", name: "MYR" },
                    { id: "USD", name: "USD" },
                  ]}
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      origin: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  source="destination"
                  data-test-id="destination"
                  validate={[required()]}
                  fullWidth
                  choices={[
                    { id: "HKD", name: "HKD" },
                    { id: "IDR", name: "IDR" },
                    { id: "TWD", name: "TWD" },
                    { id: "MYR", name: "MYR" },
                    { id: "USD", name: "USD" },
                  ]}
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      destination: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  source="rate"
                  validate={[required()]}
                  data-test-id="rate"
                  fullWidth
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      rate: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
          </SimpleForm>
        </Box>
      </Create>
      {/* Show input totp dialog */}
      <Confirm
        id="muiDrawer"
        isOpen={openConfirmDialog}
        loading={otpCode.length < 6 ? true : false}
        title="OTP code"
        content={
          <MuiTextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e: any) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleValidateOtp}
        onClose={handleConfirmDialogClose}
        sx={{
          "& .Mui-disabled": {
            color: "#c5c5c5",
          },
        }}
      />
    </Fragment>
  );
};
