import {
  Button,
  CircularProgress,
  Chip,
  TextField as MuiTextField,
  Box,
  Tab
} from "@mui/material";
import * as React from "react";
import { Key, Done, Close, Visibility } from "@mui/icons-material";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  SearchInput,
  BooleanField,
  usePermissions,
  FilterButton,
  TopToolbar,
  FunctionField,
  useRecordContext,
  useNotify,
  SelectInput,
  Confirm,
  useRefresh,
  useRedirect,
  RaRecord,
  TextInput,
} from "react-admin";
import mbaas from "../../provider/Mbaas";
import CustomExportButton from "../CustomExportButton";
import Swal from "sweetalert2";
import MigrateAs2in1UserButton from "./MigrateAs2in1UserDialog";
import { DateRange } from "../DateRange";
import { useDispatch } from "react-redux";
import { setSubscribersFilter } from "src/slices/subscribersPage/filterSubscribersSlice";
import { usePersistFilter } from "src/hooks/usePersistFilter";
import { byIso } from "country-code-lookup";
import { TabContext, TabList } from '@mui/lab';
import ReactJson from "react-json-view";
/** for demo need only */
/** import { authenticator } from "otplib"; */

const statusList = [
  { id: "APPROVED", name: "APPROVED" },
  { id: "REJECTED", name: "REJECTED" },
  { id: "PENDING", name: "PENDING" },
];

const ListActions = ({ userData, filter }: any) => {
  usePersistFilter();
  return (
    <TopToolbar>
      <FilterButton />
      <MigrateAs2in1UserButton />
      <CustomExportButton exportBtnType="subscribers" userData={userData} filter={filter}/>
    </TopToolbar>
  );
};

const ListPage = () => {
  const { isLoading, permissions } = usePermissions();
  const [userData, setUserData] = React.useState({});
  const isAdmin =
    !isLoading &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");
  const [reset, setReset] = React.useState<string>("");
  const [passportNumber, setPassportNumber] = React.useState<string>("");
  const notify = useNotify();
  const dispatch = useDispatch();
  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  const resetPassRequest = async (email: string) => {
    console.log(email);
    setReset(email);
    try {
      const forget = await mbaas.client.auth.forgotPassword(email);
      console.log(forget);
      notify(`Success send email to ${email} for reset password`, {
        type: "success",
      });
    } catch (e) {
      notify("Something went wrong", { type: "warning" });
    }
    setReset("");
  };

  const ActionColumn = (props: any) => {
    const record = useRecordContext(props);
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [otpCode, setOtpCode] = React.useState("");
    const [isLoadingOtp, setIsLoadingOtp] = React.useState(false);
    const onConfirmHandler = async () => {
      setIsLoadingOtp(true);
      const { error: setPromotorError, data: setPromotorData } =
        await mbaas.client.invokeApi("console-set-promotor", {
          token: otpCode,
          user_id: record.id,
        });
      if (setPromotorError) {
        setOtpCode("");
        setIsLoadingOtp(false);
        setConfirmOpen(false);
        Swal.fire(`Request failed: ${setPromotorError?.error}`, "", "error");
      }
      if (setPromotorData === "OK") {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
          undoable: true,
        });
        refresh();
        Swal.fire("Success!", "", "success");
        setOtpCode("");
        setIsLoadingOtp(false);
        setConfirmOpen(false);
      }
    };
    return (
      <>
        <Button
          variant="contained"
          size="small"
          startIcon={<Visibility />}
          color="primary"
          onClick={() => redirect(`/subscribers/${record.id}/show`)}
        >
          {"Show"}
        </Button>
        &nbsp;
        <Button
          variant="contained"
          size="small"
          startIcon={record.email === reset ? "" : <Key />}
          color="primary"
          disabled={record.email === reset}
          onClick={() => resetPassRequest(record.email)}
        >
          {record.email === reset ? (
            <CircularProgress style={{ width: "25px", height: "25px" }} />
          ) : (
            "Reset Password"
          )}
        </Button>
        &nbsp;
        <Button
          variant="contained"
          size="small"
          startIcon={record.is_promotor ? <Close /> : <Done />}
          color="primary"
          onClick={() => setConfirmOpen(true)}
        >
          {record.is_promotor ? "Disable Promotor" : "Enable Promotor"}
        </Button>
        <Confirm
          isOpen={confirmOpen}
          loading={otpCode.length < 6 ? true : isLoadingOtp}
          title="OTP code"
          content={
            <MuiTextField
              type="number"
              fullWidth
              data-test-id="input-otp"
              value={otpCode}
              onChange={(e) => setOtpCode(e.target.value)}
              /** for demo need only */
              // onMouseOver={() => {
              //   const token = authenticator.generate("GVHTSPYMCAZGESAQ");
              //   setOtpCode(token);
              // }}
            />
          }
          onConfirm={onConfirmHandler}
          onClose={() => setConfirmOpen(false)}
        />
      </>
    );
  };
  ActionColumn.defaultProps = { label: "Action", addLabel: true };

  const VerifiedList = (props: any) => {
    const record = useRecordContext(props);
    return (
      <span>
        <Chip
          label="Email"
          style={{ margin: "0px 5px 5px 5px" }}
          size="small"
          color={record.verified ? "success" : "default"}
        />
        <Chip
          label="Account"
          style={{ margin: "0px 5px 5px 5px" }}
          size="small"
          color={record.is_account_verified ? "success" : "default"}
        />
        <Chip
          label="Number"
          style={{ margin: "0px 5px 5px 5px" }}
          size="small"
          color={record.is_number_verified ? "success" : "default"}
        />
      </span>
    );
  };
  VerifiedList.defaultProps = { label: "Verified", addLabel: true };

  const listFilters = [
    <DateRange alwaysOn />,
    <SearchInput
      source="keyword"
      alwaysOn
      onChange={(e) =>
        //@ts-ignore
        dispatch(setSubscribersFilter({ keyword: e.target.value }))
      }
      resettable={false}
    />,
    <SelectInput
      source="status"
      label="Status"
      choices={statusList}
      alwaysOn
      onChange={(e) =>
        //@ts-ignore
        dispatch(setSubscribersFilter({ status: e.target.value }))
      }
    />,
    <TextInput 
      source="msisdn" 
      label="Virtual MSISDN" 
      sx={{ maxWidth: "170px" }} 
      alwaysOn
      onChange={(e) =>
        //@ts-ignore
        dispatch(setSubscribersFilter({ msisdn: e.target.value }))
      }
    />,
    <TextInput 
      source="passport_number" 
      label="Passport Number" 
      sx={{ maxWidth: "170px" }} 
      alwaysOn
      onChange={(e) =>
        {
          //@ts-ignore
          dispatch(setSubscribersFilter({ passport_number: e.target.value }))  
          setPassportNumber(e.target.value)
        }
      }
    />,
  ];

  const [value, setValue] = React.useState('other');
  const [filter, setFilter] = React.useState({ });

  React.useEffect(() => {
    if (value === 'taiwan') {
      setFilter({ service: "G2" });
    } else if (value === 'hongkong') {
      setFilter({ service: "GHK" });
    } else if (value === 'other') {
      setFilter({ service: "M21" });
    }
  }, [value]);

  const handleChange = (e: React.SyntheticEvent, newValue: string) => {
    e.preventDefault();
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="M21" value="other" />
            <Tab label="MyGrapariTaiwan" value="taiwan" />
            <Tab label="MyGrapariHongkong" value="hongkong" />
          </TabList>
        </Box>
      </TabContext>
      <List
        filters={listFilters}
        filter={filter}
        perPage={25}
        sort={{ field: "created_at", order: "ASC" }}
        debounce={1200}
        actions={<ListActions userData={userData} filter={filter}/>}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="email" />
          <TextField source="name" />
          <FunctionField label="Country" render={(row: RaRecord) => {
            const lookup = byIso(row.country)
            return lookup?.country
          }} />
          <VerifiedList />
          <FunctionField
            source="deactivated_at"
            label="Blocked"
            render={(
              record: { deactivated_at: any },
              source: string | undefined
            ) => (
              <BooleanField
                record={{ ...record, deactivated_at: !!record.deactivated_at }}
                resource={source}
              />
            )}
          />
          <BooleanField source="is_deleted" label="Deleted" />
          <BooleanField source="is_promotor" label="Promotor?" />
          {/* <DateField source="created_at" showTime /> */}
          <DateField source="latest_registration_updated_at" label="Registration Last Updated" showTime />
          {passportNumber !== "" && (
            <FunctionField
              label="Passport Data"
              render={(record: any) => {
                if (record?.data_passport) {
                  return (
                    <ReactJson
                      src={record.data_passport || []}
                      collapsed={0}
                      enableClipboard={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      name={false}
                    />
                  );
                } else {
                  return "-";
                }
              }}
            />
          )}
          {isAdmin && <ActionColumn />}
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

export default ListPage;
