// @ts-nocheck
import React, { useEffect } from "react";
import {
  TabbedShowLayout,
  Tab,
  TextField,
  Show,
  FunctionField,
  usePermissions,
  DateField,
  BooleanField,
} from "react-admin";
import ReactJson from "react-json-view";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Button as MaterialButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import MvnoPricing from "./MvnoPricing";

const useStyles = makeStyles({
  detailsBox: {
    // borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "8px",
  },
  topGrid: {
    // borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "8px",
    paddingTop: "40px !important",
  },
  bottomGrid: {
    paddingBottom: "8px",
  },
  histExpTopGrid: {
    // borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
  },
  histExpDetailsBox: {
    paddingBottom: "5px",
    paddingTop: "5px !important",
  },
});

export const Detail = ({ onCancel, match, ...props }) => {
  const classes = useStyles();

  const { isLoading, permissions } = usePermissions();

  const isAdmin =
    !isLoading &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const navigate = useNavigate();

  return (
    <Show resource="servicesMvno" title=" &rsaquo; Detail" {...props}>
      <Box
        pt={5}
        width={{ xs: "100vW", sm: 600 }}
        mt={{ xs: 2, sm: 1 }}
        ml={{ xs: 2, sm: 1 }}
        mr={{ xs: 2, sm: 1 }}
        minHeight="100vh"
      >
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <TabbedShowLayout>
          <Tab label="detail">
            <Grid container spacing={2}>
              <Grid className={classes.topGrid} item xs={4}>
                <strong>Item Key</strong>
              </Grid>
              <Grid className={classes.topGrid} item xs={6}>
                <TextField data-testid="item_key" source="item_key" />
              </Grid>
              <Grid className={classes.topGrid} item xs={2}>
                {isAdmin && (
                  <MaterialButton
                    style={{ float: "right" }}
                    startIcon={<EditIcon />}
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() =>
                      navigate(`/services/mvno/${match.params.id}`)
                    }
                    data-testid="edit_button"
                  >
                    Edit
                  </MaterialButton>
                )}
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>Item Group</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <TextField source="item_group" data-testid="item_group" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>Label</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <TextField source="label" data-testid="label" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>Description</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <TextField source="description" data-testid="description" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>Retail Price</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <TextField source="retail_price" data-testid="retail_price" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>Available?</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <BooleanField
                  source="is_available"
                  data-testid="is_available"
                />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>Auto Enabled?</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <BooleanField
                  source="auto_enabled"
                  data-testid="auto_enabled"
                />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>Inquiry Rules</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <FunctionField
                  data-testid="inquiry-rules-json"
                  render={(record) => {
                    if (record.inquiry_rules) {
                      return (
                        <ReactJson
                          src={record.inquiry_rules || {}}
                          shouldCollapse={() => {
                            /* */
                          }}
                          enableClipboard={false}
                          displayDataTypes={false}
                          displayObjectSize={false}
                          name={false}
                        />
                      );
                    } else {
                      return "-";
                    }
                  }}
                />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>Execution Rules</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <FunctionField
                  data-testid="execution-rules-json"
                  render={(record) => {
                    return (
                      <ReactJson
                        src={record.execution_rules || {}}
                        shouldCollapse={() => {
                          /* */
                        }}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        name={false}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid className={classes.bottomGrid} item xs={4}>
                <strong>Metadata</strong>
              </Grid>
              <Grid className={classes.bottomGrid} item xs={8}>
                <FunctionField
                  render={(record) => {
                    return (
                      <ReactJson
                        src={record.metadata || {}}
                        shouldCollapse={() => {
                          /** */
                        }}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        name={false}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Tab>
          <Tab label="Pricing" path="pricing" data-test-id="tab-pricing">
            <MvnoPricing />
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
};
