import * as React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  DateTimeInput,
  Toolbar,
  SaveButton,
  BooleanInput,
  usePermissions,
  FormDataConsumer,
  required,
  SelectInput,
} from "react-admin";
import { Box } from "@mui/material";

const UserEditToolbar = (props: any) => {
  const { isLoading, permissions } = usePermissions();
  console.log({ isLoading, permissions });

  const isAdmin =
    !isLoading &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");

  return (
    <Toolbar {...props}>
      <SaveButton disabled={!isLoading && !isAdmin} />
    </Toolbar>
  );
};

const countryList = [
  { id: "EN", name: "English" },
  { id: "ID", name: "Bahasa Indonesia" },
];

const EditPage = () => {
  return (
    <Edit>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <TextInput
            source="id"
            label="Record ID"
            isRequired
            fullWidth
            disabled
          />
          <Box width={"32px"} />
          <DateTimeInput source="created_at" fullWidth disabled />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <TextInput source="email" isRequired fullWidth disabled />
          <Box width={"32px"} />{" "}
          <TextInput
            source="phone"
            fullWidth
            defaultValue={""}
            required
            disabled
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <TextInput source="name" fullWidth defaultValue={""} />
          <Box width={"32px"} />{" "}
          <TextInput
            source="max_coins"
            fullWidth
            defaultValue={""}
            type="number"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <TextInput
            source="max_daily_purchase"
            fullWidth
            defaultValue={""}
            type="number"
          />
          <Box width={"32px"} />
          <TextInput
            source="max_monthly_purchase"
            fullWidth
            defaultValue={""}
            type="number"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <TextInput source="token" fullWidth defaultValue={""} required />
        </Box>
        {/* <BooleanInput source="is_blocked" label="Blocked" fullWidth /> */}
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData.deactivated_at !== null) {
                return (
                  <BooleanInput
                    source="edit_deactivated"
                    label="Blocked true "
                    fullWidth
                  />
                );
              } else {
                return (
                  <BooleanInput
                    source="edit_deactivated"
                    label="Blocked false"
                    fullWidth
                  />
                );
              }
            }}
          </FormDataConsumer>
          <BooleanInput source="is_deleted" label="Deleted" fullWidth />
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default EditPage;
