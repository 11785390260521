// @ts-nocheck
import React, { Fragment, useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  Button,
  useInput,
  Toolbar,
  SaveButton,
  useRedirect,
  Confirm,
  useRefresh,
  SelectInput,
  AutocompleteInput
} from "react-admin";
import "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-textmate";
import AceEditor from "react-ace";
import { makeStyles } from "@mui/styles";
import { Box, Grid, IconButton, Stack, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { dataProvider } from "../../../provider";
import Mbaas from "../../../provider/Mbaas";
import Swal from "sweetalert2";
import countryList from "../../../constant/countries.json";
/** for demo need only */
/** import { authenticator } from "otplib"; */

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const InquiryRulesInput = (props) => {
  const [inquiryRulesValue, setInquiryRulesValue] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const inquiryRules = useInput(props);

  useEffect(() => {
    setInquiryRulesValue(JSON.stringify(inquiryRules.field.value, null, "\t"));
    setIsChanged(false);
  }, [inquiryRules.field.value]);

  function onChange(newValue) {
    setInquiryRulesValue(newValue);
    setIsChanged(true);
  }

  function saveToForm() {
    const parsed = JSON.parse(inquiryRulesValue) || {};
    inquiryRules.field.onChange(parsed);
  }

  return (
    <div>
      <AceEditor
        name="inquiryRules"
        style={{ height: "200px", width: "100%" }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={inquiryRulesValue}
      />
      {isChanged && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveToForm}
        >
          <>Commit Changes</>
        </Button>
      )}
    </div>
  );
};

const ExecutionRulesInput = (props) => {
  const [executionRulesValue, setExecutionRulesValue] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const executionRules = useInput(props);

  useEffect(() => {
    setExecutionRulesValue(
      JSON.stringify(executionRules.field.value, null, "\t")
    );
    setIsChanged(false);
  }, [executionRules.field.value]);

  function onChange(newValue) {
    setExecutionRulesValue(newValue);
    setIsChanged(true);
  }

  function saveToForm() {
    const parsed = JSON.parse(executionRulesValue) || {};
    executionRules.field.onChange(parsed);
  }

  return (
    <div>
      <AceEditor
        name="executionRules"
        style={{ height: "200px", width: "100%" }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={executionRulesValue}
      />
      {isChanged && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveToForm}
        >
          <>Commit Changes</>
        </Button>
      )}
    </div>
  );
};

const MetadataInput = (props) => {
  const [metadataValue, setMetadataValue] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const metadata = useInput(props);

  useEffect(() => {
    setMetadataValue(
      JSON.stringify(metadata.field.value, null, "\t")
    );
    setIsChanged(false);
  }, [metadata.field.value]);

  function onChange(newValue) {
    setMetadataValue(newValue);
    setIsChanged(true);
  }

  function saveToForm() {
    const parsed = JSON.parse(metadataValue) || {};
    metadata.field.onChange(parsed);
  }

  return (
    <div>
      <AceEditor
        name="metadata"
        style={{ height: "200px", width: "100%" }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={metadataValue}
      />
      {isChanged && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveToForm}
        >
          <>Commit Changes</>
        </Button>
      )}
    </div>
  );
};
export const EditMvno = ({ onCancel, id, ...props }) => {
  const classes = useStyles();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [packageData, setPackageData] = useState(null);

  const onSubmitHandler = (data) => {
    setConfirmOpen(true);
    setPackageData(data);
  };
  const onCloseHandlerConfirm = () => {
    setConfirmOpen(false);
  };

  const redirect = useRedirect();
  const refresh = useRefresh();

  const onConfirmHandler = async () => {
    setIsLoadingOtp(true);
    const { error: valTotpErr, data: valTotpData } =
      await Mbaas.client.invokeApi("console-validate-totp", {
        token: otpCode,
      });
    if (valTotpErr) {
      Swal.fire(`Request failed: ${valTotpErr?.error}`, "", "error");
    }
    if (valTotpData === "OK") {
      await dataProvider
        .update("servicesMvno", { data: packageData, id })
        .then(() => {
          redirect(`/services/mvno/${id}/show`);
          refresh();
          Swal.fire("Success!", "", "success");
        });
    }
    setIsLoadingOtp(false);
    setConfirmOpen(false);
  };

  const ToolbarEdit = () => {
    return (
      <Toolbar classes={useStyles()}>
        <SaveButton />
      </Toolbar>
    );
  };

  return (
    <Fragment>
      <Confirm
        isOpen={confirmOpen}
        loading={otpCode.length < 6 ? true : isLoadingOtp}
        title="OTP code"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          /** for demo need only */
          // onMouseOver={() => {
          //   const token = authenticator.generate("GVHTSPYMCAZGESAQ");
          //   setOtpCode(token);
          // }}
          />
        }
        onConfirm={onConfirmHandler}
        onClose={onCloseHandlerConfirm}
      />
      <Edit resource="servicesMvno" title=" &rsaquo; Edit" {...props}>
        <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
          <Stack
            direction="row"
            p={0}
            sx={{ position: "absolute", right: 0, zIndex: 100 }}
          >
            <IconButton onClick={onCancel} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
          <SimpleForm onSubmit={onSubmitHandler} toolbar={<ToolbarEdit />}>
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    source="item_key"
                    data-testid="item_key"
                    label="Item Key"
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    source="item_group"
                    data-testid="item_group"
                    label="Item Group"
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    source="label"
                    data-testid="label"
                    label="Label"
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    source="description"
                    data-testid="description"
                    label="Description"
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    source="retail_price"
                    data-testid="retail_price"
                    label="Retail Price"
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectInput
                    source="is_vas"
                    label="Mvno Type"
                    fullWidth
                    choices={[
                      { id: true, name: "Product" },
                      { id: false, name: "Package" },
                    ]}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AutocompleteInput
                    fullWidth
                    source="country"
                    data-testid="country"
                    label="Country"
                    validate={required()}
                    choices={countryList.data.map((m) => ({ id: m.code, name: m.name }))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectInput
                    source="mvno_country"
                    label="Mvno Country"
                    fullWidth
                    choices={[
                      { id: "HK", name: "HK" },
                      { id: "TW", name: "TW" },
                    ]}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BooleanInput
                    fullWidth
                    className={classes.input}
                    source="is_available"
                    data-testid="is_available"
                    label="Available?"
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>Inquiry Rules</p>
                  <InquiryRulesInput source="inquiry_rules" />
                </Grid>
                <Grid item xs={12}>
                  <p>Execution Rules</p>
                  <ExecutionRulesInput source="execution_rules" />
                </Grid>
                <Grid item xs={12}>
                  <p>Metadata</p>
                  <MetadataInput source="metadata" />
                </Grid>
                <Grid item xs={6}>
                  <BooleanInput
                    fullWidth
                    className={classes.input}
                    source="auto_enabled"
                    data-testid="auto_enabled"
                    label="Auto Enabled?"
                    validate={required()}
                  />
                </Grid>
              </Grid>
            </div>
          </SimpleForm>
        </Box>
      </Edit>
    </Fragment>
  );
};
