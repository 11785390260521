import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { format } from "date-fns";
import gqltag, { gql } from "graphql-tag";
import moment from "moment";
import { GetListParams, GetListResult, GetOneParams, GetOneResult, CreateParams, CreateResult, GetManyParams, GetManyReferenceParams, GetManyReferenceResult, GetManyResult, UpdateParams, UpdateResult } from "react-admin";
import { dataProvider } from ".";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import Mbaas from "./Mbaas";
import { isEmpty } from "lodash";

export class SubscribersProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    console.log("MORLEY", { method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    const filterArrayX: any[] = [];
    if (params.filter.q) {
      filterArray.push({
        name: {
          _ilike: `%${params.filter.q}%`,
        },
      });
    }
    if (params.filter.keyword) {
      filterArrayX.push({
        email: {
          _ilike: `%${params.filter.keyword}%`,
        },
      });

      filterArrayX.push({
        name: {
          _ilike: `%${params.filter.keyword}%`,
        },
      });

      filterArrayX.push({
        phone: {
          _ilike: `%${params.filter.keyword}%`,
        },
      });
    }

    if (params.filter.dateGte || params.filter.dateLte) {
      filterArray.push({
        latest_registration_updated_at: {
          _gte: moment.utc(params.filter.dateGte).startOf("day").toISOString(),
        },
      });
      filterArray.push({
        latest_registration_updated_at: {
          _lte: moment.utc(params.filter.dateLte).endOf("day").toISOString(),
        },
      });
    }

    if (params.filter.status) {
      filterArray.push({
        status: {
          _eq: params.filter.status,
        },
      });
    }

    if (params.filter.service) {
      if (params.filter.service === "M21") {
        filterArray.push({
          is_subscriber: {
            _eq: true,
          },
          is_m21: {
            _eq: true,
          },
        });
      } else if (params.filter.service === "GHK") {
        filterArray.push({
          is_grapari: {
            _eq: true,
          },
          country: {
            _eq: "HK",
          },
        });
      } else if (params.filter.service === "G2") {
        filterArray.push({
          is_grapari: {
            _eq: true,
          },
          country: {
            _eq: "TW",
          },
        });
      }
    }

    if (params.filter.phone) {
      filterArray.push({
        phone: {
          _in: params.filter.phone,
        },
      });
    }

    if (params.filter.msisdn) {
      filterArray.push({
        virtual_msisdn_user_id_list: {
          msisdn: {
            _ilike: `${params.filter.msisdn}%`,
          },
        },
      });
    }

    let dataPassport: any;
    if (params.filter.passport_number) {
      const { data } = await Mbaas.client.invokeApi("console-check-passport", {
        passport_number: params.filter.passport_number || "",
      });
      if (!isEmpty(data)) {
        const dataID = data.map((item: any) => item.user_id);
        dataPassport = data;
        filterArray.push({
          id: {
            _in: dataID,
          },
        });
      } else {
        dataPassport = [];
        return {
          data: dataPassport,
          total: dataPassport.length,
        };
      }
    }

    where.roles = {
      _has_keys_any: ["Subscriber"],
    };

    if (filterArray.length > 0) {
      if (params.filter.q) {
        where._or = filterArray;
      } else {
        where._and = filterArray;
      }
    }
    if (filterArrayX.length > 0) {
      where._or = filterArrayX;
    }

    console.log(371294124, "filterArray", filterArray);

    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });
    const { allUsersList, error } = await this.client.gql.query({
      query: `
      query usersList($where: UsersWhereInput) {
        allUsersList(where: $where, ${args}) {
          data {
            id
            name
            email
            phone
            roles
            created_at
            updated_at
            deactivated_at
            status
            latest_registration_updated_at
            is_blocked
            is_deleted
            is_mvno
            verified
            country
            is_subscriber
            is_number_verified
            is_account_verified
            max_coins
            max_daily_purchase
            max_monthly_purchase
            preferred_language
            registrations_user_id_list(order:DESC, limit:3){
              data{
                created_at
                updated_at
                user_id
                remark
                status
                
              }
            }
            virtual_msisdn_user_id_list{
              data{
                msisdn
              }
            }
            is_promotor
          }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });
    console.log("LINDBERGH", allUsersList.data);

    if (error) {
      console.warn(error);
      throw new Error("GraphQL query error");
    }

    if (params.filter.passport_number) {
      const returnedData = allUsersList.data
        .map((item: any) => {
          const found = dataPassport.find((obj: any) => obj.user_id.includes(item.id));

          let passportData: any;
          if (found) {
            passportData = { passport: found?.passport, registration_status: found?.registration_status };
          }
          return found ? { ...item, data_passport: passportData } : { ...item };
        })
        .filter(Boolean);
      return {
        data: returnedData,
        total: allUsersList.count,
      };
    }

    return {
      data: allUsersList.data,
      total: allUsersList.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ method: "getOne", resource, params });

    const { getUsersById } = await this.client.gql.query({
      query: gqltag`
      query aUser($id:UUID) {
        getUsersById(id:$id) {
          id
          name
          email
          roles
          created_at
          deactivated_at
          verified
          phone
          status
          secret
          is_blocked
          is_deleted
          referral_code
          referral_referrer_id
          referral_detail
          max_coins
          max_daily_purchase
          max_monthly_purchase
          preferred_language
          wallets_user_id_list(where: {
            unit: {
              _eq: "HKD"
            }
          }){
            data{
              balance
              unit
            }
          }
          referral_referrer_id_data{
            id
            name
            email
            phone
          }
          virtual_msisdn_user_id_list{
            data{
              id
              msisdn
              expiry_date
            }
          }
          registrations_user_id_list(sort : created_at, order: DESC) {
            data{
            id
            created_at
            updated_at
            type
            user_id
            status
            user_id_data {
              name
            }
            verified_by
            passports_registration_id_list {
              data {
                id
                created_at
                updated_at
                registration_id
                country_code
                passport_number
                full_name
                citizenship
                date_of_expiry
                document_id
                selfie_id
              }
              count
            }
            identity_cards_registration_id_list {
              data{
                id
                nik
                full_name
                place_of_birth
                date_of_birth
                sex
                citizenship
                date_of_expiry
                document_id
                signature_id
              }
              count
            }
            }
          }
         is_promotor
        }
      }
      `,
      variables: {
        id: params.id,
      },
    });

    console.log(getUsersById);
    const balance = getUsersById?.wallets_user_id_list?.data.length > 0 ? getUsersById?.wallets_user_id_list?.data[0]?.balance : 0;
    const unit = getUsersById?.wallets_user_id_list?.data.length > 0 ? getUsersById?.wallets_user_id_list?.data[0]?.unit : "-";
    const vn_msisdn = getUsersById?.virtual_msisdn_user_id_list?.data.length > 0 ? getUsersById?.virtual_msisdn_user_id_list?.data[0].msisdn : "-";
    const vn_expiry = getUsersById?.virtual_msisdn_user_id_list?.data.length > 0 ? format(new Date(getUsersById?.virtual_msisdn_user_id_list?.data[0]?.expiry_date), "dd/MM/yyyy") : "-";
    const mappedData = {
      ...getUsersById,
      current_balance: `${balance + " " + unit}`,
      vn_msisdn: vn_msisdn,
      vn_expiry: vn_expiry,
    };

    return { data: mappedData };
  }

  async getMany(resource: string, params: GetManyParams): Promise<GetManyResult<any>> {
    console.log({ method: "getMany", resource, params });
    const { getUsersById } = await this.client.gql.query({
      query: `
      query aUser($id:UUID) {
        getUsersById(id:$id) {
          id
          name
          email
          roles
          created_at
          deactivated_at
          verified
          phone
          status
          is_blocked
          is_deleted
        }
      }
      `,
      variables: {
        id: params.ids[0],
      },
    });
    return { data: [getUsersById] };
  }

  async getManyReference(resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult<any>> {
    console.log({ method: "getManyReference", resource, params });

    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });

    if (params.filter.table === "quota") {
      const { allQuotaLedgersList, error } = await this.client.gql.query({
        query: `
      query allQuotaLedgersList($where: QuotaLedgersWhereInput) {
        allQuotaLedgersList(where : $where,  ${args}){
          data {
            id
            created_at
            updated_at
            user_id
            unit
            credit
            debit
            remark
            active_package_id
            voice_cdr_id
            sms_cdr_id
          }
          count
        }
      }
      `,
        variables: {
          where: {
            user_id: { _eq: params.id },
            unit: { _eq: params.filter.type },
            _and: [
              {
                created_at: {
                  _gte: moment(params.filter.start).startOf("day").toISOString(),
                },
              },
              {
                created_at: {
                  _lte: moment(params.filter.end).endOf("day").toISOString(),
                },
              },
            ],
          },
        },
      });
      console.log({ allQuotaLedgersList, error });
      if (error) {
        console.warn(error);
        throw new Error("GraphQL query error");
      }
      return {
        data: allQuotaLedgersList.data,
        total: allQuotaLedgersList.count,
      };
    }
    if (params.filter.table === "wallet") {
      const { allWalletLedgersList, error } = await this.client.gql.query({
        query: `
      query allWalletLedgersList($where: WalletLedgersWhereInput) {
        allWalletLedgersList(where : $where, ${args}){
          data {
            id
            created_at
            updated_at
            wallet_id_data {
              id
              user_id_data{
                id
                name
                email
              }
            }
            unit
            credit
            debit
            balance
            remark
            metadata
            wallet_reload_log_id
            voice_cdr_id
            sms_cdr_id
            purchase_log_id
            purchase_log_id_data{
              product_catalog_id_data{
                item_group
              }
            }
          }
          count
        }
      }
      `,
        variables: {
          where: {
            wallet_id_data: { user_id: { _eq: params.id } },
            unit: { _eq: params.filter.type },
            _and: [
              {
                created_at: {
                  _gte: moment(params.filter.start).startOf("day").toISOString(),
                },
              },
              {
                created_at: {
                  _lte: moment(params.filter.end).endOf("day").toISOString(),
                },
              },
            ],
          },
        },
      });
      console.log("WALLET");

      console.log({ allWalletLedgersList, error });
      if (error) {
        console.warn(error);
        throw new Error("GraphQL query error");
      }
      const mappedData = allWalletLedgersList.data.map((el: any) => {
        return {
          ...el,
          remark: el.purchase_log_id === null ? el.remark : `[${el.purchase_log_id_data?.product_catalog_id_data?.item_group}] ${el?.remark}`,
        };
      });

      return {
        data: mappedData,
        total: allWalletLedgersList.count,
      };
    }
    if (params.filter.table === "package") {
      const { allActivePackagesList, error } = await this.client.gql.query({
        query: `
      query allActivePackagesList($where: ActivePackagesWhereInput) {
        allActivePackagesList(where : $where, ${args}){
          data {
            id
            created_at
            updated_at
            purchase_log_id
            user_id
            package_detail
            expiry_date
            is_recurring
            remark
          }
          count
        }
      }
      `,
        variables: {
          where: {
            _and: [
              {
                created_at: {
                  _gte: moment(params.filter.start).startOf("day").toISOString(),
                },
              },
              {
                created_at: {
                  _lte: moment(params.filter.end).endOf("day").toISOString(),
                },
              },
              { user_id: { _eq: params.id } },
            ],
          },
        },
      });
      console.log({ allActivePackagesList, error });
      if (error) {
        console.warn(error);
        throw new Error("GraphQL query error");
      }
      return {
        data: allActivePackagesList.data,
        total: allActivePackagesList.count,
      };
    }
    if (params.filter.table === "activities") {
      let where: Record<string, any> = {};
      const filterArray: any[] = [];
      if (params.filter.start || params.filter.end) {
        filterArray.push({
          created_at: {
            _gte: moment(params.filter.start).startOf("day").toISOString(),
          },
        });
        filterArray.push({
          created_at: {
            _lte: moment(params.filter.end).endOf("day").toISOString(),
          },
        });
      }
      if (filterArray.length > 0) where._and = filterArray;

      if (params.filter.cdr === "sms") {
        if (params?.target) {
          filterArray.push({
            virtual_msisdn_id_data: {
              user_id: {
                _eq: params.id,
              },
            },
          });
        }
        if (params.filter.msisdn) {
          where._or = [
            {
              counterparty_msisdn: {
                _ilike: `%${params.filter.msisdn}%`,
              },
            },
            {
              virtual_msisdn_id_data: {
                msisdn: {
                  _ilike: `%${params.filter.msisdn}%`,
                },
              },
            },
          ];
        }
        const queryResult = await this.client.gql.query({
          query: gql`
            query subsSmsCdrAct($where: SmsCdrWhereInput) {
              allSmsCdrList(where: $where) {
                data {
                  id
                  created_at
                  direction
                  virtual_msisdn_id_data {
                    msisdn
                  }
                  counterparty_msisdn
                  status
                  hkd_cost
                }
                count
              }
            }
          `,
          variables: {
            where,
          },
        });
        const queryResultData = queryResult?.allSmsCdrList?.data.map((x: any) => {
          return {
            ...x,
            data: {},
          };
        });
        console.log(773124, "queryResultData sms", queryResultData);
        return {
          data: queryResultData || [],
          total: queryResult?.allSmsCdrList?.count || 0,
        };
      }
      if (params.filter.cdr === "voice") {
        if (params?.target) {
          filterArray.push({
            subscriber_id: {
              _eq: params.id,
            },
          });
        }
        if (params.filter.msisdn) {
          where._or = [
            {
              counterparty_msisdn: {
                _ilike: `%${params.filter.msisdn}%`,
              },
            },
            {
              subscriber_id_data: {
                phone: { _ilike: `%${params.filter.msisdn}%` },
              },
            },
            {
              subscriber_id_data: {
                virtual_msisdn_user_id_list: {
                  msisdn: { _ilike: `%${params.filter.msisdn}%` },
                },
              },
            },
          ];
        }
        const queryResult = await this.client.gql.query({
          query: gql`
            query subsVoiceCdrAct($where: VoiceCdrWhereInput) {
              allVoiceCdrList(where: $where) {
                data {
                  id
                  created_at
                  direction
                  service_rate_id_data {
                    destination_country
                    rate
                    version
                    is_active
                  }
                  subscriber_id_data {
                    phone
                    name
                    virtual_msisdn_user_id_list {
                      data {
                        msisdn
                      }
                    }
                  }
                  counterparty_msisdn
                  status
                  hkd_cost
                  begin
                  end
                  duration
                }
                count
              }
            }
          `,
          variables: {
            where,
          },
        });
        const counterpartyName = await this.fetchCounterPartyData(queryResult?.allVoiceCdrList?.data);

        const queryResultData = queryResult?.allVoiceCdrList?.data.map((x: any) => {
          const vn = x.subscriber_id_data.virtual_msisdn_user_id_list.data[0]?.msisdn || "";
          const phone = x.subscriber_id_data.phone;
          let a_number = "";
          let a_name = "";
          let b_number = "";
          let b_name = "";
          if (x.direction === "MO") {
            a_number = vn;
            a_name = x.subscriber_id_data.name;
            b_number = x.counterparty_msisdn;
            b_name = counterpartyName[x.counterparty_msisdn];
          } else {
            a_number = x.counterparty_msisdn;
            a_name = counterpartyName[x.counterparty_msisdn];
            b_number = phone;
            b_name = x.subscriber_id_data.name;
          }
          return {
            ...x,
            a_number,
            b_number,
            a_name,
            b_name,
            number: vn,
            data: {
              start: moment(x.begin).format("MM/DD/YYYY, hh:mm:ss A"),
              end: moment(x.end).format("MM/DD/YYYY, hh:mm:ss A"),
              duration: x.duration,
            },
          };
        });
        console.log(773124, "queryResultData voice", queryResultData);
        return {
          data: queryResultData || [],
          total: queryResult?.allVoiceCdrList?.count || 0,
        };
      }
    }
    if (params.filter.table === "kyc") {
      const { allRegistrationsList, error } = await this.client.gql.query({
        query: gqltag`
      query allRegistrationsList($where: RegistrationsWhereInput) {
        allRegistrationsList(where : $where, ${args}){
          data {
            id
            created_at
            updated_at
            user_id
            type
            status
            verified_by
            remark
            user_id_data{
              id
              name
              email
              secret
            }
            verified_by_data{
              id
              name
              email
            }
            passports_registration_id_list(sort: created_at, order: DESC) {
              data {
                id
                created_at
                updated_at
                registration_id
                country_code
                passport_number
                full_name
                citizenship
                date_of_expiry
                document_id
                selfie_id
              }
            }
            identity_cards_registration_id_list(sort: created_at, order: DESC) {
              data{
                id
                registration_id
                nik
                full_name
                place_of_birth
                date_of_birth
                sex
                citizenship
                date_of_expiry
                document_id
                signature_id
              }
            }
          }
          count
        }
      }
      `,
        variables: {
          where: {
            user_id: { _eq: params.id },
            _and: [
              {
                created_at: {
                  _gte: moment(params.filter.start).startOf("day").toISOString(),
                },
              },
              {
                created_at: {
                  _lte: moment(params.filter.end).endOf("day").toISOString(),
                },
              },
            ],
          },
        },
      });
      console.log({ allRegistrationsList, error });
      if (error) {
        console.warn(error);
        throw new Error("GraphQL query error");
      }
      return {
        data: allRegistrationsList.data,
        total: allRegistrationsList.count,
      };
    }

    if (params.filter.table === "device_logs") {
      const { allDeviceLogsList, error } = await this.client.gql.query({
        query: `
        query allDeviceLogsList($where: DeviceLogsWhereInput){
          allDeviceLogsList(where : $where, ${args}){
            data{
              id
              info
              created_at
              fingerprint
              user_id_data{
                id
                name
                email
              }   
            }
            count
          }
        }
        `,
        variables: {
          where: {
            user_id_data: {
              id: { _eq: params.id },
            },
            _and: [
              {
                created_at: {
                  _gte: moment(params.filter.start).startOf("day").toISOString(),
                },
              },
              {
                created_at: {
                  _lte: moment(params.filter.end).endOf("day").toISOString(),
                },
              },
            ],
          },
        },
      });
      console.log(7782345, { allDeviceLogsList, error });
      if (error) {
        console.warn(error);
        throw new Error("GraphQL query error");
      }
      return {
        data: allDeviceLogsList.data,
        total: allDeviceLogsList.count,
      };
    }

    if (params.filter.table === "referral") {
      const queryResult = await this.client.gql.query({
        query: gql`
          query aUser($id: UUID) {
            getUsersById(id: $id) {
              id
              referral_detail
            }
          }
        `,
        variables: {
          id: params.id,
        },
      });
      let returnedData = [];
      const dataM21 = queryResult?.getUsersById?.referral_detail?.referee_by_promotor.map((x: any) => ({ ...x, app: "M21" }));
      const dataGHK = queryResult?.getUsersById?.referral_detail?.referee_by_promotor_ghk.map((x: any) => ({ ...x, app: "GHK" }));

      switch (params.filter.app) {
        case "M21":
          returnedData = dataM21;
          break;
        case "GHK":
          returnedData = dataGHK;
          break;
        default:
          returnedData = [...dataM21, ...dataGHK];
      }
      const refereeIds = returnedData.map((x: any) => x.referee_id);
      const { data: usersData } = await dataProvider.getList("users", {
        filter: {
          refereeIds,
        },
        pagination: { page: 1, perPage: 1000 },
        sort: {
          field: "name",
          order: "ASC",
        },
      });
      returnedData = returnedData
        .map((x: any) => {
          return {
            ...x,
            id: x.referee_id,
            name: usersData.find((user: any) => user.id === x.referee_id).name,
          };
        })
        .sort((a: any, b: any) => new Date(b.added_at).getTime() - new Date(a.added_at).getTime());
      if (params.filter.start && params.filter.end) {
        returnedData = returnedData.filter((x: any) => moment(x.added_at).isBetween(moment(params.filter.start).startOf("day"), moment(params.filter.end).endOf("day")));
      }
      if (params.sort) {
        if (params.sort.field === "added_at")
          returnedData = returnedData.sort((a: any, b: any) => {
            if (params.sort.order === "ASC") return new Date(a.added_at).getTime() - new Date(b.added_at).getTime();
            if (params.sort.order === "DESC") return new Date(b.added_at).getTime() - new Date(a.added_at).getTime();
          });
        if (params.sort.field === "name")
          returnedData = returnedData.sort((a: any, b: any) => {
            if (params.sort.order === "ASC") return a.name.localeCompare(b.name);
            if (params.sort.order === "DESC") return b.name.localeCompare(a.name);
          });
      }
      return {
        data: returnedData,
        total: returnedData.length,
      };
    }
    return {
      data: [],
      total: 0,
    };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    console.log({ method: "create", resource, params });
    return { data: {} };
  }

  async update(resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });
    const res = await this.client.invokeApi("console-update-user", {
      token: params.data.token,
      data: {
        email: params.data.email,
        roles: typeof params.data.roles === "string" ? [params.data.roles] : params.data.roles,
        name: params.data.name,
        enabled: !params.data.edit_deactivated,
        max_coins: parseInt(params.data.max_coins),
        max_daily_purchase: parseInt(params.data.max_daily_purchase),
        max_monthly_purchase: parseInt(params.data.max_monthly_purchase),
        is_deleted: params.data.is_deleted,
      },
    });

    console.log(res);

    if (res.error) {
      throw new Error(res.error.error);
    }

    const { getUsersById } = await this.client.gql.query({
      query: `
      query aUser($id:UUID) {
        getUsersById(id:$id) {
          id
          name
          email
          roles
          created_at
          deactivated_at
          verified
          phone
          status
          is_blocked
          referral_code
          referral_referrer_id
          referral_detail
          max_coins
          max_daily_purchase
          max_monthly_purchase
          preferred_language
          is_deleted
          referral_referrer_id_data{
            id
            name
            email
            phone
          }
          registrations_user_id_list {
            data{
              id
            }
          }
        }
      }
      `,
      variables: {
        id: params.data.id,
      },
    });

    console.log(getUsersById);

    return { data: getUsersById };
  }

  async fetchCounterPartyData(cdrData: object[]) {
    const listOfCounterpartyMSISDN = new Set();
    cdrData.forEach((x: any) => listOfCounterpartyMSISDN.add(x.counterparty_msisdn));
    let counterpartyName: { [key: string]: string } = {};
    if (listOfCounterpartyMSISDN) {
      const whereParty: Record<string, any> = {};
      whereParty._or = [
        {
          phone: {
            _in: Array.from(listOfCounterpartyMSISDN),
          },
        },
        {
          virtual_msisdn_user_id_list: {
            msisdn: {
              _in: Array.from(listOfCounterpartyMSISDN),
            },
          },
        },
      ];
      const partyResults = await this.client.gql.query({
        query: gql`
          query partyList($where: UsersWhereInput) {
            allUsersList(where: $where) {
              data {
                name
                phone
                virtual_msisdn_user_id_list {
                  data {
                    msisdn
                  }
                }
              }
            }
          }
        `,
        variables: {
          where: whereParty,
        },
      });
      partyResults?.allUsersList?.data.forEach((element: { phone: string; name: string; virtual_msisdn_user_id_list: { data: { msisdn: string }[] } }) => {
        counterpartyName[element?.phone] = element.name;
        counterpartyName[element?.virtual_msisdn_user_id_list?.data[0]?.msisdn] = element.name;
      });
    }
    //
    return counterpartyName || [];
  }
}
