import React from "react";
import {
  Edit,
  SimpleForm,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  SaveButton,
  Toolbar,
  TextInput,
  FunctionField,
  RaRecord,
  Confirm,
  useUpdate,
  useGetOne,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField as MuiTextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import mbaas from "../../../provider/Mbaas";
import Swal from "sweetalert2";
import HeightJSONInput from "../../HeightJSONInput";

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  test: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: "auto",
    },
  },
});

interface IUpdatedData {
  key: string;
  value: string;
}

const EditConfigSaveButton = (props: any) => {
  const { openDialog } = props;

  return <SaveButton type="button" onClick={openDialog} />;
};

const EditConfigToolbar = (props: any) => {
  const { openDialog } = props;

  return (
    <Toolbar>
      <EditConfigSaveButton openDialog={openDialog} />
    </Toolbar>
  );
};

export const EditPage = ({ onCancel, id, ...props }: any) => {
  const { recordId } = props;

  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [otpCode, setOtpCode] = React.useState<string>("");
  const [
    update,
    { isLoading: isLoadingUpdate, isSuccess: isUpdateSuccess, error },
  ] = useUpdate();
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [updatedData, setUpdatedData] = React.useState<IUpdatedData>({
    key: "",
    value: "",
  });

  const handleConfirmDialogOpen = (e: any) => {
    e.preventDefault();
    setOpenConfirmDialog(true);
  };
  const handleConfirmDialogClose = () => setOpenConfirmDialog(false);

  // -- If success creating data
  if (isUpdateSuccess) {
    notify("Update Data Success", { type: "success" });
    redirect("/internal/firebaseRemoteConfigs");
    refresh();
  }
  // -- If error when creating data
  if (error) {
    notify("Create Data Failed", { type: "warning" });
    redirect("/internal/firebaseRemoteConfigs");
    refresh();
  }
  // -- get current data to set the updated data state
  const { data } = useGetOne(
    "firebaseRemoteConfigs",
    { id: recordId },
    { onError: () => redirect("/internal/firebaseRemoteConfigs") }
  );
  console.log(776123, "data", data);
  // -- set the updated data state
  React.useEffect(() => {
    setUpdatedData({
      key: data?.id,
      value: data?.value,
    });
  }, [data]);
  // --
  const handleValidateOtp = async () => {
    const { data: resultData, error } = await mbaas.client.invokeApi(
      "console-validate-totp",
      {
        token: otpCode,
      }
    );
    // --
    if (error) {
      Swal.fire({
        icon: "error",
        title: `Request failed: ${error.error}`,
        target: "#muiDrawer",
      });
    }
    // --
    if (resultData) {
      handleConfirmDialogClose();
      update("firebaseRemoteConfigs", {
        id: recordId,
        data: { ...updatedData },
        previousData: { id: recordId, ...updatedData },
      });
    }
  };

  return (
    <Edit resource="firebaseRemoteConfigs" title=" &rsaquo; Edit" {...props}>
      <Box
        pt={5}
        width={{ xs: "100vW", sm: 600 }}
        mt={{ xs: 2, sm: 1 }}
        height="100vh"
      >
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, top: 10, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm
          sx={{ width: "100%" }}
          toolbar={<EditConfigToolbar openDialog={handleConfirmDialogOpen} />}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput
                disabled
                source="id"
                validate={[required()]}
                multiline
                label="id"
                fullWidth
                data-test-id="idInput"
                onChange={(e) =>
                  setUpdatedData((prevState: any) => ({
                    ...prevState,
                    id: e.target.value,
                  }))
                }
              />
            </Grid>
            {/* <Grid item xs={6}>
              <TextInput
                disabled
                source="description"
                validate={[required()]}
                multiline
                label="description"
                fullWidth
                data-test-id="descriptionInput"
                onChange={(e) =>
                  setUpdatedData((prevState: any) => ({
                    ...prevState,
                    description: e.target.value,
                  }))
                }
              />
            </Grid> */}
            <Grid item xs={12}>
              {/* <MuiTextField
                fullWidth
                rows={7}
                data-test-id="value"
                multiline
                label="value"
                value={updatedData?.value}
                onChange={(e) =>
                  setUpdatedData((prevState: any) => ({
                    ...prevState,
                    value: e.target.value,
                  }))
                }
              /> */}
              <HeightJSONInput
                data-test-id="additional_data"
                source="value"
                onChange={(val: any) => {
                  console.log("CHANGED", val);

                  setUpdatedData((prevState: any) => ({
                    ...prevState,
                    value: val,
                  }));
                }}
              />
            </Grid>
          </Grid>
        </SimpleForm>
        {/* Show input totp dialog */}
        <Confirm
          id="muiDrawer"
          isOpen={openConfirmDialog}
          loading={otpCode.length < 6 ? true : false}
          title="OTP code"
          content={
            <MuiTextField
              type="number"
              fullWidth
              data-test-id="input-otp"
              value={otpCode}
              onChange={(e: any) => setOtpCode(e.target.value)}
            />
          }
          onConfirm={handleValidateOtp}
          onClose={handleConfirmDialogClose}
          sx={{
            "& .Mui-disabled": {
              color: "#c5c5c5",
            },
          }}
        />
      </Box>
    </Edit>
  );
};
